import React from 'react';
import BlogListPage from '../components/blog/BlogListPage';
import useAllPosts from '../components/blog/useAllPosts';
import Meta from '../components/layout/Meta';
import { BlogPost } from '../types';

interface BlogProps {
  posts: BlogPost[];
}

const TITLE = 'Knock Down Silos';
const DESCRIPTION = 'A modern take on writing & knowledge-sharing within teams';

const Blog: React.SFC<BlogProps> = ({ posts }) => (
  <>
    <Meta title={`${TITLE} - ${DESCRIPTION}`} />
    <BlogListPage posts={posts} title={TITLE} description={DESCRIPTION} />
  </>
);

export default () => <Blog posts={useAllPosts()} />;
